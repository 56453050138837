import React from 'react'
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaLinkedinIn } from "react-icons/fa";

const SocialMedia = () => {
  return (
 <div className="app__social">
    <div>
    <a href="https://twitter.com/devbyrahul" target="_blank">
        <BsTwitter />
        </a>
    </div>
    <div>
    <a href="https://www.linkedin.com/in/devbyrahul/" target="_blank">
        <FaLinkedinIn />
        </a>
    </div>
    <div>
    <a href="https://www.instagram.com/devbyrahul/" target="_blank">
        <BsInstagram />
        </a>
    </div>
 </div>
  )
}

export default SocialMedia