import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import  {Tooltip as ReactTooltip}  from 'react-tooltip'


import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';

import './Skills.scss';

const Skills = () => {
  const [experience, setExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query)
     .then((data) => {

      const sortedData = data.sort((a, b) => b.year - a.year); // Sort data in descending order
      setExperience(sortedData);
     })

     client.fetch(skillsQuery)
     .then((data) => {
      setSkills(data);
     })
  }, [])

  return (
    <>
     <h2 className="head-text">Skills & Trainings</h2>

     <div className="app__skills-container">
      <motion.div className="app__skills-list">
        {skills && skills.map((skill) => (
          <motion.div
           whileInView={{opacity: [0, 1]}}
           transition={{ duration: 0.5 }}
           className="app__skills-item app__flex"
           key={skill.name}
          >
            <div className="app__flex" style={{ backgroundColor: skill.bgColor }}>
              <img src={urlFor(skill.icon)} alt={skill.name} />
            </div>
            <p className="p-text">{skill.name}</p>
          </motion.div>
        ))}
      </motion.div>
      <motion.div className="app__skills-exp">
      {experience&&experience.map((work) => (
          <motion.div
            className="app__skills-exp-item"
            key={work.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{work.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {work.works.map((elem) => (
                 <>
                  <motion.div
                    whileInView={{opacity: [0, 1]}}
                    transition={{ duration: 0.5 }}
                    className="app__skills-exp-work"
                    data-tip
                    data-for={elem.name}
                    key={elem.name}
                  >
                   <h4 className="bold-text">{elem.name}</h4>
                   <p className="p-text">{elem.company}</p>
                  </motion.div>
                  <ReactTooltip
                    id={elem.name}
                    effect="solid"
                    arrowColor="#fff"
                    className="skills-tooltip"
                    >
                    {elem.desc}
                  </ReactTooltip>
                </>
                ))}
              </motion.div>
          </motion.div>
          ))}
  
      </motion.div>
     </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  "app__whitebg"
  );